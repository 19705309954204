import { useUserRequest, RequestOptions, HttpMethod } from "../common/lib/fetch";
import { useUser } from "../userAccess/useUser";

export enum DispenseType {
  Blended = "Blended",
  Bulk = "Bulk",
  Both = "Both"
}

export interface Product {
  id: string;
  name: string;
  enabled: boolean;
}

export interface Bay {
  id: string;
  name: string;
  dispenseType: DispenseType;
  products: Product[];
}

export interface ProductUpdate extends Omit<Product, "name"> { };

export interface BayUpdate extends Omit<Bay, "name" | "products"> {
  products: ProductUpdate[]
};

export default function useUpdateBaySettings(options?: RequestOptions) {
  const user = useUser();

  return useUserRequest<BayUpdate[]>({
    path: `/api/sites/${user.selectedSite!.id}/bayDirectionsSettings`,
    method: HttpMethod.PUT,
  }, options);
}
