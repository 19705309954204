import { HttpMethod } from "../common/lib/fetch/useFetch";
import { RequestOptions } from "../common/lib/fetch/useRequest";
import useUserRequest from "../common/lib/fetch/useUserRequest";
import { User } from "../userAccess/UserContext";

export enum TicketOptions {
  Print = "Print",
  Email = "Email",
  PrintAndEmail = "PrintAndEmail",
}

export interface TicketSettingsDto {
  ticketOptions: TicketOptions;
}

export default function useUpdateTicketSettings(user: User, options?: RequestOptions) {
  return useUserRequest<TicketSettingsDto>({
    path: `/api/sites/${user.selectedSite!.id}/ticketSettings`,
    method: HttpMethod.PUT
  }, options)
}
