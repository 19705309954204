import { SubmitHandler, useForm } from "react-hook-form";
import { HttpMethod } from "../../common/lib/fetch/useFetch";
import useUserQuery from "../../common/lib/fetch/useUserQuery";
import useUserRequest from "../../common/lib/fetch/useUserRequest";
import useDisplayMessage from "../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../common/lib/KaAlert";
import QueryView from "../../common/lib/QueryView";
import SpinnerButton from "../../common/SpinnerButton";
import { adminFacingErrorMessage } from "../../common/userFacingMessages/userFacingMessages";
import useUpdateTicketSettings, { TicketOptions, TicketSettingsDto } from "../../requests/useUpdateTicketSettings";
import { User } from "../../userAccess/UserContext";
import { useUser } from "../../userAccess/useUser";

const TicketSettings = () => {
  const displayMessage = useDisplayMessage();
  const user = useUser(); 

  const useGetTicketSettings = useUserQuery<TicketSettingsDto>({
    path: `/api/sites/${user.selectedSite!.id}/settings`
  }, {
    onError: (err) => displayMessage.fail(adminFacingErrorMessage(err))
  });

  return <QueryView
    query={useGetTicketSettings}
    renderData={response => <>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <TicketSettingsForm 
        initialSettings={response.ticketOptions}
        user={user} />
    </>
  }/>
}

interface TicketSettingsFormProps {
  initialSettings: TicketOptions,
  user: User
}

interface FormTicketSettings {
  setting: TicketOptions
}

const TicketSettingsForm = (props: TicketSettingsFormProps) => {
  const displayMessage = useDisplayMessage();

  const updateTicketSettings = useUpdateTicketSettings(props.user, {
    onSuccess: () => displayMessage.success("Ticket settings updated"),
    onError: (err) => displayMessage.fail(adminFacingErrorMessage(err))
  });

  const { register, handleSubmit } = useForm<FormTicketSettings>({
    defaultValues: { setting: props.initialSettings },
  });

  const onSubmit: SubmitHandler<FormTicketSettings> = (formValue) => updateTicketSettings.request({ticketOptions: formValue.setting});

  return <>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <form data-testid="ticketSettings-form" className="m-auto w-50">
      <h2>Ticket Settings</h2>
      <div className="border border-dark rounded">
        <div className="m-3">
          <div className="form-check">
            <input type="radio" value="Print" id="PrintTickets" className="form-check-input" {...register("setting")}/>
            <label className="form-check-label" htmlFor="PrintTickets">Print Tickets</label>
          </div>
          <div className="form-check">
            <input type="radio" value="Email" id="EmailTickets" className="form-check-input" {...register("setting")}/>
            <label className="form-check-label" htmlFor="EmailTickets">Email Tickets</label>
          </div>
          <div className="form-check">
            <input type="radio" value="PrintAndEmail" id="PrintAndEmailTickets" className="form-check-input" {...register("setting")}/>
            <label className="form-check-label" htmlFor="PrintAndEmailTickets">Print and Email Tickets</label>
          </div>
        </div>
      </div>
    </form>
    <div className="align-self-end">
      <SpinnerButton 
        className="btn btn-primary mx-4 mb-4 px-5 py-3" 
        spinning={updateTicketSettings.isLoading}
        onClick={handleSubmit(onSubmit)}>
        Save
      </SpinnerButton>
    </div>
  </>
}

export { TicketSettings };
