import { NavLink } from 'react-router-dom';
import { useUser } from '../../userAccess/useUser';

export function AdminNavSideMenu() {
  const user = useUser();

  return (
    <div className="container">
      <nav className="navbar navbar-expand-sm navbar-sidebar navbar-vertical navbar-light bg-white">
        <div className="navbar-brand-wrapper" style={{ height: 'auto' }}>
          <div className="d-flex align-items-center">
            <a className="navbar-brand" href="/" target="_self" rel="noreferrer" aria-label="LP_Logo">
              <img className="navbar-brand-logo" src="/images/lp_logo.png" alt="LoadPass" width='100' style={{ minWidth: '12rem', maxWidth: '12rem' }} />
            </a>
          </div>
        </div>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sideNavbar" aria-controls="sideNavbar" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-default">
            <i className="bi-list"></i>
          </span>
          <span className="navbar-toggler-toggled">
            <i className="bi-x"></i>
          </span>
        </button>

        <div className="collapse navbar-collapse navbar-sidebar-aside-body" id="sideNavbar">
          {
            user.selectedSite &&
            <ul className="navbar-nav nav nav-vertical nav-tabs nav-tabs-borderless">
              <li className="nav-item">
                <NavLink className="nav-link px-3" to="/carrierDriverSettings">
                  Carrier/Driver Info
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-3" to="/customerDestination">
                  Customer Destination
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-3" to="/scaleAutomation">
                  Scale Automation
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-3" to="/loadQuestions">
                  Load Questions
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-3" to="/ticketSettings">
                  Ticket Settings
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-3" to="/bayDirections">
                  Bay Directions
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-3" to="/bayDirectionsValidation">
                  Bay Directions Validation
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-3" to="/kioskManagement">
                  Kiosk Management
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-3" to="/loadConfiguration">
                  Load Configuration
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-3" to="/stagedOrders">
                  Staged Orders
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-3" to="/businessHours">
                  Business Hours
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-3" to="/generalSettings">
                  General
                </NavLink>
              </li>
            </ul>
          }
        </div>
        <div className="position-absolute bottom-0 start-0">
          <a className="navbar-brand" href="https://www.kahlerautomation.com" target="_blank" rel="noreferrer" aria-label="KA_Logo">
            <img className="navbar-brand-logo m-2" src="/images/logo.png" alt="Logo" width='100' style={{ minWidth: '15rem', maxWidth: '15rem' }} />
          </a>
        </div>
      </nav>
    </div>
  );
}
