import { useState } from "react";
import { Table, UncontrolledTooltip } from "reactstrap";
import useDisplayMessage from "../../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../../common/lib/KaAlert";
import QueryView from "../../../common/lib/QueryView";
import OverflowTooltip from "../../../common/OverflowTooltip";
import Row from "../../../common/Row";
import SpinnerButton from "../../../common/SpinnerButton";
import { adminFacingErrorMessage } from "../../../common/userFacingMessages/userFacingMessages";
import useGetSiteSettings from "../../../requests/useGetSiteSettings";
import useUpdateBaySettings, { Bay, BayUpdate, DispenseType, Product, ProductUpdate } from "../../../requests/useUpdateBaySettings";
import { UpdateBayModal } from "./UpdateBayModal";

export interface BaySettings {
  bays: Bay[];
  products: Product[];
}

const dispenseTypeDisplay = (dispenseType: DispenseType) => {
  switch (dispenseType) {
    case DispenseType.Blended:
      return "Blends";

    case DispenseType.Bulk:
      return "Bulk";

    case DispenseType.Both:
      return "Bulk & Blends";
  }
}

const BayDirections = () => {
  const getBaySettings = useGetSiteSettings<BaySettings>();

  return <QueryView
    query={getBaySettings}
    renderData={settings =>
      !settings.bays.length
        ? <div className="alert alert-dismissible alert-danger" role="alert">
          Current site does not have any bays
        </div>
        : <Form initialBays={settings.bays.sort((a, b) => a.name.localeCompare(b.name))}
          products={settings.products.sort((a, b) => a.name.localeCompare(b.name))} />
    } />
}

interface FormProps {
  initialBays: Bay[],
  products: Product[],
}

const Form = (props: FormProps) => {
  const displayMessage = useDisplayMessage();
  const [bays, setBays] = useState<Bay[]>(props.initialBays);
  const [selectedBay, setSelecedBay] = useState<Bay | null>(null);

  const updateBaySettings = useUpdateBaySettings({
    onSuccess: () => displayMessage.success("Bay settings updated"),
    onError: err => displayMessage.fail(adminFacingErrorMessage(err))
  });

  const toggleProduct = (ev: React.ChangeEvent<HTMLInputElement>, bayIndex: number, id: string) => {
    const updatedBays: Bay[] = JSON.parse(JSON.stringify(bays));

    const productIndex = updatedBays[bayIndex].products.findIndex(p => p.id === id);

    if (productIndex !== -1) {
      updatedBays[bayIndex].products[productIndex].enabled = ev.target.checked;
      setBays(updatedBays);
    }
  }

  const changeDispenseType = (bay: Bay, dispenseType: DispenseType) => {
    const updatedBays: Bay[] = JSON.parse(JSON.stringify(bays));
    const bayIndex = updatedBays.findIndex(b => b.id === bay.id);
    updatedBays[bayIndex].dispenseType = dispenseType;
    setBays(updatedBays);
    setSelecedBay(null);
  }

  const bulkWarning = (bay: Bay, product: Product) => {
    if (bay.dispenseType === DispenseType.Both && bay.products.some(p => p.id === product.id && p.enabled)) {
      return bays
        .filter(b => b.dispenseType === DispenseType.Bulk)
        .some(b => b.products.some(p => p.id === product.id && p.enabled))
    }

    return false;
  }

  const save = () => {
    const bayUpdates = bays.map<BayUpdate>(b => ({
      id: b.id,
      dispenseType: b.dispenseType,
      products: b.products.map<ProductUpdate>(p => ({ id: p.id, enabled: p.enabled }))
    }));

    updateBaySettings.request(bayUpdates);
  }

  return <>
    {
      selectedBay &&
      <UpdateBayModal
        bay={selectedBay}
        onUpdate={(dispenseType) => changeDispenseType(selectedBay, dispenseType)}
        toggle={() => setSelecedBay(null)} />
    }
    <div className="mx-auto w-100">
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <div className="row mb-2">
        <h2 className="col-4">Bay Directions</h2>
        <p>
          The Bay Directions page allow users to setup which products are available at each bay, ensuring accurate product placement.
          This information is then used in the LOADPASS app to provide drivers with precise bay directions and loading instructions. Bays are assigned
          when the driver scans their code after arriving at the site.
        </p>
      </div>
      <div className="row p-1 border border-dark rounded overflow-auto">
        <Table borderless>
          <thead>
            <tr>
              <th />
              {bays.map(b => <th key={b.id}>
                <div style={{ minWidth: "175px" }} >
                  <OverflowTooltip id={b.id} text={b.name} className="h4" />
                </div>
                <Row className="flex-nowrap">
                  <p className="col-auto mb-0 pe-1">({dispenseTypeDisplay(b.dispenseType)})</p>
                  <div className="col text-start align-self-end ms-0 p-0 mt-n1">
                    <button className="btn p-1 m-0 d-inline-block" onClick={() => setSelecedBay(b)} >
                      <i className="bi-pencil-square" data-testid={`btn-edit-${b.id}`} />
                    </button>
                  </div>
                </Row>
              </th>)}
            </tr>
          </thead>
          <tbody>
            {
              props.products.map(p =>
                <tr key={p.name}>
                  <td className="py-0 d-inline-block" style={{ width: "175px" }}>
                    <p className="text-truncate">{p.name}</p>
                  </td>
                  {bays.map((b, bIndex) => {
                    return <td key={b.id} className="py-0">
                      <div className="d-flex">
                        <div className="form-check form-switch mb-4 flex-shrink-0" id={`cbx-${b.id}-${p.id}`}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            data-testid={`cbx-${b.id}-${p.id}`}
                            disabled={!b.products.some(bayProduct => bayProduct.id === p.id)}
                            onChange={(ev) => toggleProduct(ev, bIndex, p.id)}
                            checked={b.products.find(bayProduct => bayProduct.id === p.id)?.enabled ?? false} />
                          {
                            bulkWarning(b, p) &&
                            <i className="bi-info-circle" id={`info-${b.id}-${p.id}`} data-testid={`info-${b.id}-${p.id}`} />
                          }
                        </div>
                      </div>
                      {
                        b.products.find(bayProduct => bayProduct.id === p.id) === undefined &&
                        <UncontrolledTooltip placement="top" target={`cbx-${b.id}-${p.id}`}>
                          {p.name} is not configured to be dispensed at this bay
                        </UncontrolledTooltip>
                      }
                      {
                        bulkWarning(b, p) &&
                        <UncontrolledTooltip placement="top" target={`info-${b.id}-${p.id}`}>
                          Bulk loads containing {p.name} will not use this bay due to higher priority Bulk bays
                        </UncontrolledTooltip>
                      }
                    </td>
                  })}
                </tr>
              )
            }
          </tbody>
        </Table>
      </div>
    </div>
    <div className="align-self-end mt-3">
      <SpinnerButton
        className="btn btn-primary mx-4 mb-4 px-5 py-3"
        spinning={updateBaySettings.isLoading}
        onClick={save}>
        Save
      </SpinnerButton>
    </div>
  </>
}

export { BayDirections };
