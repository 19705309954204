import { useReducer } from "react";
import { Kiosk } from "./userAccess/kiosk/KioskContext";
import { StagingSettings, useGetStagingSettings } from "./requests/useGetStagingSettings";
import KaAlert from "./common/lib/KaAlert";
import StageOrderFlow from "./pages/StageOrderFlow/StageOrderFlow";
import KioskTareWeight from "./pages/TareWeight/KioskTareWeight";
import GrossWeight from "./pages/GrossWeight";
import { Weight } from "./common/units/useWeight";
import Signature from "./pages/Signature/Signature";
import TicketInstructions from "./pages/TicketInstructions";
import CarrierDriver from "./pages/StageOrderFlow/CarrierDriver";
import StagedOrderNumber from "./pages/StagedOrderNumber";
import { OutboundStagedOrder } from "./requests/useGetOutboundStagedOrder";
import { KioskStart } from "./pages/KioskStart";
import { useKiosk } from "./userAccess/kiosk/useKiosk";
import AnonymousBayDirections from "./pages/BayDirections/AnonymousBayDirections";
import { StagedOrder } from "./pages/StageOrderFlow/StageOrderTypes";
import { TicketOptions } from "./requests/useUpdateTicketSettings";
import useInterval from "./common/lib/hooks/useInterval";

enum Steps {
  Start,
  CarrierDriver,
  StageOrder,
  Tare,
  BayDirections,
  OrderNumber,
  Gross,
  Sign,
  Ticket,
}

enum ActionType {
  StagingCancelled,
  SettingsUpdated,
  InboundPicked,
  OutboundPicked,
  Authenticated,
  StagedOrderCreated,
  TareWeightTaken,
  BayDirectionsConfirmed,
  OrderNumberEntered,
  GrossAccepted,
  Signed,
  TicketConfirmed,
}

type Context = { settings: StagingSettings, kiosk: Kiosk };

type State =
  | { step: Steps.Start } & Context
  | { step: Steps.StageOrder } & Context
  | { step: Steps.Tare, so: StagedOrder } & Context
  | { step: Steps.BayDirections, so: StagedOrder } & Context
  | { step: Steps.CarrierDriver } & Context
  | { step: Steps.OrderNumber } & Context
  | { step: Steps.Gross, soId: string, tare: Weight } & Context
  | { step: Steps.Sign, soId: string, } & Context
  | { step: Steps.Ticket, soId: string, ticketSettings: TicketOptions } & Context;

type Action =
  | { type: ActionType.StagingCancelled }
  | { type: ActionType.SettingsUpdated, settings: StagingSettings }
  | { type: ActionType.InboundPicked }
  | { type: ActionType.OutboundPicked }
  | { type: ActionType.StagedOrderCreated, so: StagedOrder }
  | { type: ActionType.TareWeightTaken }
  | { type: ActionType.Authenticated }
  | { type: ActionType.OrderNumberEntered, stagedOrder: OutboundStagedOrder }
  | { type: ActionType.GrossAccepted }
  | { type: ActionType.Signed, ticketSettings: TicketOptions }
  | { type: ActionType.BayDirectionsConfirmed }
  | { type: ActionType.TicketConfirmed }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SettingsUpdated:
      return state.step === Steps.Start
        ? { ...state, settings: action.settings }
        : { ...state };

    case ActionType.StagingCancelled:
      return { ...state, step: Steps.Start };

    case ActionType.InboundPicked:
      return { ...state, step: Steps.StageOrder };

    case ActionType.OutboundPicked:
      if (state.settings.requireAuthentication) {
        return { ...state, step: Steps.CarrierDriver };
      }
      return { ...state, step: Steps.OrderNumber };

    case ActionType.Authenticated:
      return { ...state, step: Steps.OrderNumber };

    case ActionType.OrderNumberEntered:
      if (!action.stagedOrder.hasBeenGrossed && action.stagedOrder.requiresGross) {
        const so = action.stagedOrder;
        return {
          ...state,
          step: Steps.Gross,
          soId: so.id,
          tare: { value: so.tareValue, unit: so.tareUnit },
        };
      }
      return { ...state, step: Steps.Sign, soId: action.stagedOrder.id, };

    case ActionType.GrossAccepted:
      if (state.step === Steps.Gross) {
        return { ...state, step: Steps.Sign };
      }
      break;

    case ActionType.Signed:
      if (state.step === Steps.Sign) {
        return { ...state, ...action, step: Steps.Ticket };
      }
      break;

    case ActionType.StagedOrderCreated:
      if (state.step === Steps.StageOrder) {
        if (action.so.requiresTare)
          return { ...state, ...action, step: Steps.Tare };
        else
          return { ...state, ...action, step: Steps.BayDirections };
      }
      return state;


    case ActionType.TareWeightTaken:
      if (state.step === Steps.Tare) {
        return { ...state, ...action, step: Steps.BayDirections };
      }
      return state;

    case ActionType.BayDirectionsConfirmed:
    case ActionType.TicketConfirmed:
  }

  return { ...state, step: Steps.Start };
}

const settingsRefreshTimeMs = 1000 * 30;

const AnonymousFlow = ({ siteId, settings }: { siteId: string, settings: StagingSettings }): JSX.Element => {
  const kiosk = useKiosk();
  const [state, dispatch] = useReducer(reducer, { settings, kiosk, step: Steps.Start });
  const useStagingSettings = useGetStagingSettings(siteId, {
    autoQuery: false,
    onSuccess: settings => dispatch({ type: ActionType.SettingsUpdated, settings })
  });

  useInterval(() => useStagingSettings.query(), settingsRefreshTimeMs, false);

  switch (state.step) {
    case Steps.Start:
      return <KioskStart
        role={state.kiosk.role}
        openStatus={state.settings.openStatus}
        hours={state.settings.hours}
        timeZone={state.settings.timeZone}
        toInbound={() => dispatch({ type: ActionType.InboundPicked })}
        toOutbound={() => dispatch({ type: ActionType.OutboundPicked })} />

    case Steps.StageOrder:
    case Steps.Tare:
    case Steps.BayDirections:
      return <Inbound state={state} dispatch={dispatch} />

    case Steps.CarrierDriver:
    case Steps.OrderNumber:
    case Steps.Gross:
    case Steps.Sign:
    case Steps.Ticket:
      return <Outbound state={state} dispatch={dispatch} />
  }
}

type SubstateProps = {
  state: State,
  dispatch: React.Dispatch<Action>,
}

const Outbound = ({ state, dispatch }: SubstateProps) => {
  const carrierDriverEntered = () =>
    dispatch({ type: ActionType.Authenticated });

  const stagedOrderReceived = (stagedOrder: OutboundStagedOrder) =>
    dispatch({ type: ActionType.OrderNumberEntered, stagedOrder });

  const grossWeightAccepted = () =>
    dispatch({ type: ActionType.GrossAccepted });

  const signed = (ticketSettings: TicketOptions) =>
    dispatch({ type: ActionType.Signed, ticketSettings });

  const ticketConfirmed = () => dispatch({ type: ActionType.TicketConfirmed });

  const ticketInstructionsMessage = (ticketSettings: TicketOptions): string => {
    switch (ticketSettings) {
      case TicketOptions.Print:
      case TicketOptions.PrintAndEmail:
        return "Pick up ticket at printer";
      case TicketOptions.Email:
        return "Order complete";
    }
  }

  switch (state.step) {
    case Steps.CarrierDriver:
      return <CarrierDriver
        siteId={state.kiosk.siteId}
        enableRememberMe={false}
        carrierDriverEntered={carrierDriverEntered}
        {...state.settings} />

    case Steps.OrderNumber:
      return <StagedOrderNumber
        siteId={state.kiosk.siteId}
        kioskId={state.kiosk.id}
        stagedOrderReceived={stagedOrderReceived} />

    case Steps.Gross:
      return <GrossWeight
        siteId={state.kiosk.siteId}
        scaleId={state.kiosk.scaleId!}
        stagedOrderId={state.soId}
        tareWeight={state.tare}
        allowReject={false}
        grossAccepted={grossWeightAccepted} />

    case Steps.Sign:
      return <Signature
        stagedOrderId={state.soId}
        signatureSubmitted={signed} />

    case Steps.Ticket:
      return <TicketInstructions
        stagedOrderId={state.soId}
        instructions={ticketInstructionsMessage(state.ticketSettings)}
        additionalInstructions={undefined}
        loadCompleted={ticketConfirmed} />
  }

  return <KaAlert displayMessage={{ message: 'Invalid state: ' + JSON.stringify(state, null, 2), success: false }} />
}

const Inbound = ({ state, dispatch }: SubstateProps) => {
  if (!state.settings.requireAuthentication && state.step === Steps.CarrierDriver) {
    dispatch({ type: ActionType.Authenticated });
  }

  const stagingCancelled = () =>
    dispatch({ type: ActionType.StagingCancelled });

  const stagedOrderCreated = (so: StagedOrder) =>
    dispatch({ type: ActionType.StagedOrderCreated, so });

  const tareWeightTaken = () => dispatch({ type: ActionType.TareWeightTaken });

  const bayDirectionsConfirmed = () =>
    dispatch({ type: ActionType.BayDirectionsConfirmed });

  switch (state.step) {
    case Steps.StageOrder:
      return <StageOrderFlow
        enableRememberMe={false}
        siteId={state.kiosk.siteId}
        kioskId={state.kiosk.id}
        settings={state.settings}
        stagedOrderCreated={stagedOrderCreated}
        loadQuestionsAnswerRejected={stagingCancelled} />

    case Steps.Tare:
      return <KioskTareWeight
        siteId={state.kiosk.siteId}
        scaleId={state.kiosk.scaleId!}
        soId={state.so.id}
        requestedAmount={state.so.requestedAmount}
        tareAccepted={tareWeightTaken} />

    case Steps.BayDirections:
      return <AnonymousBayDirections
        stagedOrderId={state.so.id}
        orderNumber={state.so.orderNumber}
        shouldPrint={state.settings.printBarcodesAtKiosk}
        bayName={state.so.bayName!}
        confirmed={bayDirectionsConfirmed} />
  }

  return <KaAlert displayMessage={{ message: 'Invalid state: ' + JSON.stringify(state, null, 2), success: false }} />
}

export default AnonymousFlow;
