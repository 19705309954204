import { Trans } from "@lingui/macro";
import { useState } from "react";
import ContainerTextHeader from "../common/ContainerTextHeader";
import Footer from "../common/Footer";
import FooterButton from "../common/FooterButton";
import useInterval from "../common/lib/hooks/useInterval";
import useIsAnonymous from "../common/lib/hooks/useIsAnonymous";
import SwitchToMobileQRCode from "../common/SwitchToMobileQRCode";
import ScanButton from "../dev/ScanButton";
import { useGetPastOrderByStagedOrderId } from "../requests/useGetPastOrderByStagedOrderId";

interface TicketInstructionsProps {
  stagedOrderId: string,
  instructions: string,
  additionalInstructions: string | undefined,
  loadCompleted: () => void,
}

const TicketInstructions = (props: TicketInstructionsProps) => {
  const [stagedOrderComplete, setStagedOrderComplete] = useState<boolean>(false);
  const isAnonymous = useIsAnonymous();

  const refreshTimeMs = 3000;
  const completeTimeMs = 15000;

  const stageOrderCompleted = useGetPastOrderByStagedOrderId(props.stagedOrderId, {
    onSuccess: _ => {
      setStagedOrderComplete(true);
    }
  });

  useInterval(() => stageOrderCompleted.query(), refreshTimeMs, true);

  useInterval(() => isAnonymous && stagedOrderComplete ? props.loadCompleted() : null, completeTimeMs, false);

  return <ContainerTextHeader
    message={props.instructions}
    devToolItems={<ScanButton stagedOrderId={props.stagedOrderId} location='TicketCreated'/>}>

    {isAnonymous && <SwitchToMobileQRCode />}
    <div className="m-auto" style={{ wordBreak: "break-word" }}>
      <label className="text-center h2">
        {props.additionalInstructions}
      </label>
    </div>
    <Footer nextButton={!isAnonymous &&
      <FooterButton
        disabled={!stagedOrderComplete}
        onClick={() => props.loadCompleted()}>
        <Trans>Done</Trans>
      </FooterButton>
    } />
  </ContainerTextHeader>
}

export default TicketInstructions;
